<template>
  <div>
    <v-data-table
      :headers="Headers"
      :items="finances"
      class="elevation-1 tb px-2"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>FINANCE TABLE</b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          :to="{ name: 'Finance', params: { editUser: { ...item } } }"
          icon
          x-small
          class="mr-2"
          title="Click to edit"
          color="black"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-icon
          icon
          small
          @click="del(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <vue-excel-xlsx
      class="mt-2 text-center"
      :data="finances"
      :columns="exceldatas"
      :filename="'Finance Datas'"
      :sheetname="'Finance Sheet'"
    >
      <v-btn small rounded class="white--text" color="primary">
        Export to Excel</v-btn
      >
    </vue-excel-xlsx>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { financeData } from "@/excels";
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "item",
  mixins: [snackMixin],

  data() {
    return {
      exceldatas: financeData,
      search: "",
      Headers: [
        {text: "Withdrawal application submitted",value: "WithdrawalApplication",},
        {text: "Replenishment Application submitted",value: "ReplenishmentApplication",},
        { text: "Replenishment received", value: "ReplenishmentReceived" },
        { text: "Amount replenished", value: "AmountReplenished" },
        { text: "Government Contribution", value: "GovtContribution" },
        { text: "Interest", value: "Interest" },
        { text: "Total Income", value: "TotalIncome" },
        { text: "Goods", value: "Goods" },
        { text: "Non-Consulting", value: "NonConsulting" },
        { text: "Consultancy", value: "Consultancy" },
        { text: "Operating Cost", value: "OperatingCost" },
        { text: "Training", value: "Training" },
        { text: "Others", value: "Others" },
        { text: "Grants - CDP", value: "GrantsCdp" },
        { text: "Grants - GDP", value: "GrantsGdp" },
        { text: "Total Expenditure", value: "TotalExpenditure" },
        { text: "Balance in IDA Replenished A/C ", value: "BalanceIda" },
        { text: "Balance in Govt. Contribution A/C", value: "BalanceGovt" },
        { text: "Balance in Interest Account", value: "BalanceInterest" },
        { text: "Created By", value: "created_by" },
        { text: "Modified By", value: "modified_by" },
        { text: "Date", value: "InputDate" },
        { text: "Actions", value: "actions" },
      ],
      finances: [],
    };
  },

  created() {
    apiClient
      .get("/finance?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.finances = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    del(item) {
      const ok = confirm(`Are sure you want to delete this ${item}?`);
      if (ok) {
        this.displayMsg(`Deleting data, please wait...`);
        apiClient
          .delete(`/finance?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(`Data not successfully deleted`, "error");
              return;
            } else {
              this.finances = this.finances.filter((pt) => pt.id !== item.id);
              this.displayMsg(`${item} successfully deleted `);
            }
          })
          .catch((err) => this.displayMsg(`${err.message}`, "error"));
      }
    },
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
